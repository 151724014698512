/* eslint-disable no-console,no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {NumberWrapper, ContentWrapper, ImageButton, ImageWrapper, Title, /*DropdownArrow,*/ ActivityDropdown, CustomMenuItem, TitleContainer, DisableColor, StyledInputV2, ColorInput, StyledModal, StyledInputPassword, CheckBoxContainer, CustomLabel, CustomCheckbox, Slider, DropdownArrow } from './styles';
import { ImageUrl } from '../../utils/constants';
import { toast } from "react-toastify";
import {
  getOrientation,
  resetOrientation,
  checkImage
} from "../../utils/methods";
import {closeIcon} from '../../utils/icons';
import HoverButton from '../common/HoverButtonV2';

class CompanyInfoUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      themeValue: props.companyColor?"Customized":"Woliba Original",
      themeType: null,
      companyLogoArrow: false,
      currentPassword: null,
      newCompanyPassword: null,
      newCompanyConfirmPassword: null,
      companyId: props.companyId,
      themeColor: props.companyColor?props.companyColor:"#005C87",
      image: ImageUrl+"/"+props.companyLogo,
      logo: props.companyLogo,
      companyName: props.companyName,
      currentPInput:true,
      newPInput: true,
      newPasswordFields: false,
      showEdit: true,
      isLogoUpdated: props.companyLogo
    }
  }

  themeTypes = [
    {"key": "Woliba Original", "value": 'Woliba Original'},
    {"key": "Customized", "value": 'Customized'}
  ]

  onSelect = (name, value, key ) => {
    this.setState({
      [name]: value,
      themeValue: key
    });
    if(value=="Woliba Original"){
      this.setState({
        themeColor: "#005C87"
      });
    }
  };

  onChangeStep = (value) => {
    this.setState({
      step: value
    });
  };

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeColor = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if (file?.size / 1000000 <= 20) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            this.setState({
              image: reader.result,
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  image: baseImage,
                  logo: baseImage
                });
              });
            });
          };
        } else {
          toast.error("Please select image file less than 20MB");
          document.getElementById("event-upload-file").value = "";
        }
      } else {
        toast.error("Please Select Image File");
        document.getElementById("event-upload-file").value = "";
      }
    }
  };

  onRemoveImage = () => {
    this.setState({
      image: null,
      logo: null
    });
  };

  submitInfo = () =>{
    const { themeValue, themeColor, companyId, /*currentPassword, newCompanyPassword,*/ companyName, logo, /*newPasswordFields,*/ isLogoUpdated } = this.state;
    const {updateInformationApi} =this.props;
    const obj = {
      id: companyId,
      company_name:companyName,
      // current_password:currentPassword,
      // new_password:newPasswordFields?newCompanyPassword:currentPassword,
      header_logo:logo,
      theme:themeValue,
      primary_color:themeColor,
      fadding:25,
      is_logo_updated: isLogoUpdated!=logo?1:0
    }
    updateInformationApi(obj);
  }

  showPassword = () => {
    this.setState({
      currentPInput: !this.state.currentPInput
    });
  };

  showNewPassword = () => {
    this.setState({
      newPInput: !this.state.newPInput
    });
  };

  handleThemeChange = (index)=>{
    this.onSelect('themeType', this.themeTypes[index].key, this.themeTypes[index].value)
  }

  firstStep = () => {
    const { themeValue, companyLogoArrow, themeColor, image } = this.state;
    return(
      <ContentWrapper>
        <Title margin="0 0 15px 0">Upload Company logo<span>*</span></Title>
        <ImageWrapper>
          <div className='logo'>
            {image?<img src={image}/>:<img className='image' src={ImageUrl+"/"+"ChallengeDetailsScreen/noImageLogo.png"}/>}
          </div>
          <div className='imageContentWrapper'>
            <div className='button'>
              {image?<ImageButton background="#FFF" border="1" color="#005c87" onClick={() => this.onRemoveImage()}>Remove</ImageButton>:<ImageButton background="#005c87">
                {"Upload Logo"}
                <input
                  id="info-file"
                  type="file"
                  name="user"
                  accept=".jpeg, .png, .jpg"
                  multiple={false}
                  onChange={(e) => this.onChange(e)}
                  onClick={(e) =>
                    e.target.files[0] && this.onChange(e)
                  }
                />
              </ImageButton>}
            </div>
            <div className='note'>
              1. We would recommend using a logo with <br/>
              &nbsp;&nbsp;&nbsp;&nbsp;a transparent background.<br/>
              2. avoid using the logo with white color<br/>
              3. Dimensions will be H: 50px and W: 200-250px
            </div>
          </div>
        </ImageWrapper>
        <TitleContainer height="100px">
          <Title margin="25px 0 15px 0">Select Theme</Title>
          <ActivityDropdown
            title={themeValue? themeValue: "Select Challenge Type"}
            id="dropdown-recurring"
            onClick={() => {this.setState({ companyLogoArrow: !this.state.companyLogoArrow });}}
            onSelect={(index,/*item*/)=>this.handleThemeChange(index)}
          >
            
            {this.themeTypes && this.themeTypes.map((list, index) => (
              <CustomMenuItem      
                key={list.id}
                eventKey={index}
                active={themeValue == list.value}
              >
                {(list.value)} <img alt="profilre-img" style={{display: themeValue==list.value ? "flex": ""}} src={'/public/images/ChallengeDetailsScreen/completeCheck.png'}></img>
              </CustomMenuItem>))}              
          </ActivityDropdown>
          <DropdownArrow
            alt="image"
            top={companyLogoArrow?"68%":"65%"}
            src={companyLogoArrow? "/public/images/teamDownArrow.svg": "/public/images/dropdown_arrow.svg"}
          />
        </TitleContainer>
        <Title margin="25px 0 15px 0">Primary Color</Title>
        {themeValue=="Woliba Original"?<DisableColor>
          <img src={ImageUrl+"/"+"ChallengeDetailsScreen/disable.png"} />
        </DisableColor>:
          <ColorInput>
            <input type="color" id="favcolor" name="themeColor" value={themeColor} onChange={this.onChangeColor}/>
          </ColorInput>}
      </ContentWrapper>
    )
  }

  showPasswordField = (status) => {
    this.setState({
      newPasswordFields: status
    });
  };

  showEdit = (status) => {
    this.setState({
      showEdit: status
    });
    if(status===true){
      this.setState({
        companyName: this.props.companyName
      });
    }
  };

  secondStep = () => {
    const { currentPassword, newCompanyPassword, newCompanyConfirmPassword, newPInput, currentPInput, companyName, newPasswordFields, showEdit} = this.state;
    return(
      <ContentWrapper paddingValue="25px 105px 35px 105px">
        <Title margin="0 0 15px 0">Company Id<img src={ImageUrl+"/"+"ChallengeDetailsScreen/toolTipIcon.png"}/>{showEdit?<span className='edit' onClick={() => this.showEdit(false)}>{"Edit"}</span>:<span className='edit' onClick={() => this.showEdit(true)}>{"Default"}</span>}</Title>
        {showEdit?<StyledInputV2
          placeholder={(this.props.companyName+" "+"(Default)")}
          name="companyName"
          maxLength="100"
          bgColor="rgba(133, 192, 234, 0.15) !important"
          pColor="#005C8799"
          border="1px solid #005C874D"
          color="#005C87"
          value={companyName+" "+"(Default)"}
          disabled
        />:
          <StyledInputV2
            placeholder={(this.props.companyName+" "+"(Default)")}
            name="companyName"
            onChange={this.onChangeInput}
            maxLength="100"
            bgColor="#F7F5EB80"
            pColor="#005C874D"
            border="1px solid #005C874D"
            color="#005C87"
            value={companyName}
          />}
        <Title margin="15px 0 15px 0">Current Password<span>*</span></Title>
        <StyledInputPassword 
          bgColor="#F7F5EB80"
          pColor="#005C874D"
          border="1px solid #005C874D"
          color="#005C87"
          type="password"
        >
          <StyledInputV2
            placeholder={("Enter Current Password")}
            name="currentPassword"
            onChange={this.onChangeInput}
            value={currentPassword}
            maxLength="100"
            bgColor="#F7F5EB80"
            pColor="#005C874D"
            border="1px solid #005C874D"
            color="#005C87"
            type={currentPInput?"password":"input"}
          />
          <div className='icon'>{currentPInput?<img  src={ImageUrl+"/"+"ChallengeDetailsScreen/hideEyeIcon.png"} onClick={() => this.showPassword()}/>:<img  src={ImageUrl+"/"+"ChallengeDetailsScreen/eyeIcon.png"} onClick={() => this.showPassword()}/>}</div>
        </StyledInputPassword>
        <CheckBoxContainer checked={newPasswordFields ? true : false}>
          <Title margin="15px 0 10px 0">Do you want to set new Password</Title>
          <div className="icon">
            <CustomLabel className="switch">
              <CustomCheckbox
                type="checkbox"
                checked={newPasswordFields ? true : false}
                onClick={newPasswordFields ? () => this.showPasswordField(false) : () => this.showPasswordField(true)}
              />
              <Slider background={"#005C874D"} className="slider round" />
            </CustomLabel>
          </div>
        </CheckBoxContainer>
        {newPasswordFields&&<Title margin="15px 0 15px 0">New Company Password<span>*</span></Title>}
        {newPasswordFields&&<StyledInputPassword 
          bgColor="#F7F5EB80"
          pColor="#005C874D"
          border="1px solid #005C874D"
          color="#005C87"
          type="password"
        >
          <StyledInputV2
            placeholder={("Enter New Password")}
            name="newCompanyPassword"
            onChange={this.onChangeInput}
            value={newCompanyPassword}
            maxLength="100"
            bgColor="#F7F5EB80"
            pColor="#005C874D"
            border="1px solid #005C874D"
            color="#005C87"
            type={newPInput?"password":"input"}
          />
          <div className='icon'>{newPInput?<img  src={ImageUrl+"/"+"ChallengeDetailsScreen/hideEyeIcon.png"} onClick={() => this.showNewPassword()}/>:<img  src={ImageUrl+"/"+"ChallengeDetailsScreen/eyeIcon.png"} onClick={() => this.showNewPassword()}/>}</div>
        </StyledInputPassword>}
        {newPasswordFields&&<Title margin="15px 0 15px 0">Confirm New Company Password<span>*</span></Title>}
        {newPasswordFields&&<StyledInputPassword 
          bgColor="#F7F5EB80"
          pColor="#005C874D"
          border="1px solid #005C874D"
          color="#005C87"
          type="password"
        >
          <StyledInputV2
            placeholder={("Enter Confirm Password")}
            name="newCompanyConfirmPassword"
            onChange={this.onChangeInput}
            value={newCompanyConfirmPassword}
            maxLength="100"
            bgColor="#F7F5EB80"
            pColor="#005C874D"
            border="1px solid #005C874D"
            color="#005C87"
            type="password"
          />
          {newCompanyPassword==newCompanyConfirmPassword&&newCompanyConfirmPassword&&<div className='icon'><img className='correctPassword' src={ImageUrl+"/"+"ChallengeDetailsScreen/passwordCorrect.png"} width="22px" height="22px"/></div>}
        </StyledInputPassword>}
        {(newCompanyPassword!=newCompanyConfirmPassword)&&newCompanyConfirmPassword&&<div className='notMatched'>*password and confirm password won’t get matched, Try again.</div>}
      </ContentWrapper>
    )
  }

  hideCompanyPopup = () =>{
    this.setState(() => ({
      step: 1,
      themeValue: this.props.companyColor?"Customized":"Woliba Original",
      themeType: null,
      companyLogoArrow: false,
      currentPassword: null,
      newCompanyPassword: null,
      newCompanyConfirmPassword: null,
      companyId: this.props.companyId,
      themeColor: this.props.companyColor?this.props.companyColor:"#005C87",
      image: ImageUrl+"/"+this.props.companyLogo,
      logo: this.props.companyLogo,
      companyName: this.props.companyName,
      currentPInput:true,
      newPInput: true
    }), () => this.props.hidePopup())
  }

  render() {
    const { show } = this.props;
    const { step, themeValue, themeColor, image, currentPassword, newCompanyPassword, newCompanyConfirmPassword , newPasswordFields} = this.state;
    return (
      <StyledModal show={show} width={"600px"} height="auto" onHide={() => this.hideCompanyPopup()}>
        <NumberWrapper>
          <div className='crossIcon'>
            <HoverButton
              title="Close"
              width="24px"
              height="24px"
              svgPath={closeIcon()} 
              onClick={() => this.hideCompanyPopup()}
              backgroundColor={"#005C870D"}
              backgroundColorActive={"#005C87"}
            />
          </div>
          {/* <div className='wrapper'>
            <Number margin="0px 5px 0px 0px">1</Number>
            <img src={ImageUrl+""+"/ChallengeDetailsScreen/activeArrowIcon.png"} height="10px" width="32px"/>
            <Number disable={step===1} margin="0px 0px 0px 5px">2</Number>
          </div> */}
          <div className='stepHeading'>
            {step===1?"Now, Let’s add some style...":"Let’s, Create Your Company Login"}
          </div>
          <div className='default'>You can use the default or a present theme, or input your own brand color</div>
        </NumberWrapper>
        {/* {step===1? */}
        {this.firstStep()}
        {/* :this.secondStep()} */}
        {step===1?<ContentWrapper border padding>
          <ImageButton 
            width="200px" 
            background={ !themeValue|| !themeColor||!image?"#005C874D": "#005C87"} 
            margin="auto"
            disable={!themeValue|| !themeColor||!image}
            onClick={  (!themeValue|| !themeColor||!image)?null: () => this.submitInfo()}
          >Submit</ImageButton>
        </ContentWrapper>:
          <ContentWrapper border padding>
            <ImageButton border color="#9c9c9c" background="transparent" onClick={() => this.onChangeStep(1)}>Go Back</ImageButton>
            <ImageButton 
              width="200px" 
              background={!currentPassword|| (newPasswordFields&& (!newCompanyPassword|| !newCompanyConfirmPassword||newCompanyPassword!= newCompanyConfirmPassword))?"#005C874D":   "#005C87"} 
              margin="auto 0 auto auto" 
              disable={!currentPassword|| (newPasswordFields&&(!newCompanyPassword|| !newCompanyConfirmPassword||newCompanyPassword!= newCompanyConfirmPassword))}
              onClick={(!currentPassword|| (newPasswordFields&& (!newCompanyPassword|| !newCompanyConfirmPassword||newCompanyPassword!= newCompanyConfirmPassword)))?null:() => this.submitInfo()} 
            >Submit</ImageButton>
          </ContentWrapper>}
      </StyledModal>
    );
  }
}

CompanyInfoUpdate.propTypes = {
  show:PropTypes.bool,
  hidePopup: PropTypes.func,
  updateInformationApi: PropTypes.func,
  companyId: PropTypes.number,
  companyName: PropTypes.string,
  companyLogo: PropTypes.string,
  companyColor: PropTypes.string
};

export default CompanyInfoUpdate;