/* eslint-disable no-console,no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CompanyInfoUpdate from './companyInfoUpdate';
import Waiting from '../Waiting';
import isNull from 'lodash/isNull';

class CompanyInfoUpdateMain extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { show, hidePopup, updateInformationApi, companyInfoData } = this.props;
    if(!companyInfoData||isNull(companyInfoData)) {
      return <Waiting />;
    }
    return (
      <CompanyInfoUpdate show={show} hidePopup={hidePopup} updateInformationApi={updateInformationApi} companyId={companyInfoData && companyInfoData.companyInfo && companyInfoData.companyInfo[0].id} companyName={companyInfoData && companyInfoData.companyInfo && companyInfoData.companyInfo[0].company_name} companyLogo={companyInfoData && companyInfoData.companyInfo && companyInfoData.companyInfo[0].header_logo} companyColor={companyInfoData && companyInfoData.color_branding&&companyInfoData.color_branding[1] &&companyInfoData.color_branding[1].color_code}/>
    );
  }
}

CompanyInfoUpdateMain.propTypes = {
  show:PropTypes.bool,
  hidePopup: PropTypes.func,
  updateInformationApi: PropTypes.func,
  companyId: PropTypes.number,
  companyName: PropTypes.string,
  companyLogo: PropTypes.string,
  companyColor: PropTypes.string,
  companyInfoData: PropTypes.object
};

export default CompanyInfoUpdateMain;